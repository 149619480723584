var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-0",staticStyle:{"padding":"20px"}},[_c('h2',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("我的作业")]),_c('v-divider'),_c('v-row',{staticStyle:{"margin-top":"10px"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"作业名称","clearable":"","outlined":"","dense":""},model:{value:(_vm.search.title),callback:function ($$v) {_vm.$set(_vm.search, "title", $$v)},expression:"search.title"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","content-class":"selectmenu","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"发布时间","clearable":"","outlined":"","dense":"","readonly":""},model:{value:(_vm.search.date),callback:function ($$v) {_vm.$set(_vm.search, "date", $$v)},expression:"search.date"}},'v-text-field',attrs,false),on),[_c('v-icon',{attrs:{"slot":"append"},slot:"append"},[_vm._v("mdi-calendar")])],1)]}}]),model:{value:(_vm.search.menu),callback:function ($$v) {_vm.$set(_vm.search, "menu", $$v)},expression:"search.menu"}},[_c('v-date-picker',{attrs:{"locale":"zh-cn","no-title":"","scrollable":""},on:{"input":function($event){_vm.search.menu = false}},model:{value:(_vm.search.date),callback:function ($$v) {_vm.$set(_vm.search, "date", $$v)},expression:"search.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.searchData}},[_vm._v("查询")])],1)],1),_c('input',{ref:"uploadInput",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.upload}}),_c('v-data-table',{staticClass:"elevation-1 worktab",attrs:{"item-key":"Ys_job.id","show-select":"","headers":_vm.headers,"items":_vm.articleList,"options":_vm.options,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.uploadDate",fn:function(ref){
var item = ref.item;
return [(item.Ys_job.uploadBg)?_c('v-chip',{staticStyle:{"width":"100%"},attrs:{"label":"","color":item.Ys_job.uploadBg}},[_vm._v(_vm._s(item.Ys_job.uploadDate))]):_vm._e()]}},{key:"item.lookPerson",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":item['Ys_job_person_look_view[]'].length <= 2,"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-badge',{attrs:{"content":item['Ys_job_person_look_view[]'].length,"left":"","offset-x":"20","value":item['Ys_job_person_look_view[]'].length > 2}},[_vm._l((item['Ys_job_person_look_view[]']),function(per,index){return (index < 2)?[_c('span',{key:index,staticStyle:{"padding":"0 3px"}},[_vm._v(_vm._s(per.realname))])]:_vm._e()})],2)],1)]}}],null,true)},[_c('span',[_vm._l((item['Ys_job_person_look_view[]']),function(per,index){return [_c('span',{key:index,staticStyle:{"padding":"0 3px"}},[_vm._v(_vm._s(per.realname))])]})],2)])]}},{key:"item.uploadViews",fn:function(ref){
var item = ref.item;
return [(item.Ys_job.type == '3')?[(item.Ys_job.type == '3')?_c('v-btn',{staticStyle:{"padding":"0 !important","font-size":"1rem"},attrs:{"text":"","color":"#437EE2"},on:{"click":function($event){return _vm.doLmind(item)}}},[_c('v-icon',{attrs:{"left":true}},[_vm._v(" mdi-paperclip ")]),_vm._v(" 查看作业 ")],1):_vm._e()]:[_vm._l((item['Ys_job_upload_view[]']),function(file,index){return [_c('v-row',{key:index,attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"position":"relative"},attrs:{"cols":"8"}},[_c('v-chip',{staticClass:"uploadlist",attrs:{"close":"","outlined":"","label":"","color":"#437EE2"},on:{"click:close":function($event){return _vm.delFile(file)},"click":function($event){return _vm.handleClick(file)}}},[_c('v-icon',{attrs:{"left":true}},[_vm._v(" mdi-link-variant ")]),_vm._v(" "+_vm._s(file.file_name)+" ")],1),_c('v-icon',{staticStyle:{"position":"absolute","right":"16px","top":"8px","background":"#fff","color":"#1976d2"},attrs:{"left":true},on:{"click":function($event){return _vm.editUploadFile(file)}}},[_vm._v("mdi-pencil-box")])],1),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"learning-duration",attrs:{"label":"时长","outlined":"","dense":"","type":"number","max":"600","min":"0"},on:{"blur":function($event){return _vm.onLearningDurationBlur(file)}},model:{value:(file.learning_duration),callback:function ($$v) {_vm.$set(file, "learning_duration", $$v)},expression:"file.learning_duration"}})],1)],1)]})]]}},{key:"item.uploadBtn",fn:function(ref){
var item = ref.item;
return [(item.Ys_job.job_status > 0)?[(item.Ys_job.type == '3')?_c('v-btn',{staticStyle:{"padding":"0 !important","font-size":"1rem"},attrs:{"text":"","color":"#437EE2"},on:{"click":function($event){return _vm.doLmind(item)}}},[_c('v-icon',{attrs:{"left":true}},[_vm._v(" mdi-square-edit-outline ")]),_vm._v(" 做作业 ")],1):_c('v-btn',{staticStyle:{"padding":"0 !important","font-size":"1rem"},attrs:{"text":"","color":"#437EE2"},on:{"click":function($event){return _vm.uploadFile(item)}}},[_c('v-icon',{attrs:{"left":true}},[_vm._v(" mdi-cloud-upload-outline ")]),_vm._v(" 上传作业 ")],1)]:_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-pagination',{staticStyle:{"padding":"20px 0 0"},attrs:{"length":_vm.options.pages},model:{value:(_vm.options.current),callback:function ($$v) {_vm.$set(_vm.options, "current", $$v)},expression:"options.current"}}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogPreview),callback:function ($$v) {_vm.dialogPreview=$$v},expression:"dialogPreview"}},[_c('v-card',{staticClass:"workpre"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.indexFile.file_name))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogPreview = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('WorkPreview',{attrs:{"filename":_vm.indexFile.file_name}})],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('minde-editor',{ref:"minderEditor",attrs:{"minderData":_vm.minderData,"id":_vm.currentData.id,"businessId":_vm.currentData.businessId,"tableName":_vm.currentData.tableName,"category":_vm.currentData.category,"fieldName":_vm.currentData.fieldName},on:{"ok":_vm.loadData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }