<template>
    <v-card class="lmind-table mt-0" style="padding:15px;position:relative"> 
        <v-row style="padding: 15px;">
            <v-col cols="4">
                <div class="custom-select"> 
                    <!-- <v-select outlined dense label="类别" placeholder="请选择类别" clearable v-model="search_job_name" :items="jonNameList" ></v-select>  --> 
                    <v-autocomplete v-model="search_job_name" :items="jonNameList" item-text="text" item-value="value" dense clearable label="选择类别" placeholder="请选择类别" outlined hide-details="auto">
                    </v-autocomplete>
                </div> 
            </v-col>
            <v-col cols="3">
                <div> 
                    <v-btn color="primary"  elevation="2"  @click="handleSearch">查询</v-btn> 
                </div>
            </v-col> 
        </v-row> 
        <v-data-table
            class="elevation-1 "
            :headers="headers"
            :items="dataSource"
            item-key="id"
            :loading="loading"
        >
        <!-- :options.sync="options" :server-items-length="totalDesserts"  @pagination="pagination"  :footer-props="{'items-per-page-options': [5,10,20,50]}" -->
            <template v-slot:[`item.job_name`]="{ item }">
            <span>{{ item.job_name }}</span>
            </template>
            <template v-slot:[`item.file_name`]="{ item }">
            <span style="cursor: pointer;color:#437EE2"  @click="lookFile(item, 'lookFile', 'onlineLearn')">{{item.file_name}}</span>
            </template>
            <template v-slot:[`item.target_duration`]="{ item }">
            <span>{{item.target_duration ? item.target_duration + '分钟' : ''}}</span>
            </template>
            <template v-slot:[`item.cumulative_duration`]="{ item }">
            <span>{{item.cumulative_duration}}</span>
            </template> 
        </v-data-table>
        <v-dialog
            v-model="dialogPreview"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            >
            <v-card class="workpre">
                <v-toolbar dark color="primary" >
                <v-toolbar-title>{{indexFile.file_name}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="closeDialogPreview" >
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <WorkPreview 
                    :filename="indexFile.file_name"
                    ref="myWorkPreview"
                    @voideoPlay="voideoPlay"
                    @voideoPause="voideoPause"
                    @voideoEnded="voideoEnded"
                ></WorkPreview>
            </v-card>
        </v-dialog>
    </v-card>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import { VDataTable } from 'vuetify/lib'
  import { postAction,  httpAction2 } from '@/api/manage'
  import JSEncrypt from '@/api/jsencrypt.js'
  import WorkPreview from '../homework/workPreview.vue'
  import { matchFileSuffixType } from '@/utils/util' // 判断文件类型
  export default {
    name: 'UserAnnouncementList',
    components: {
      VDataTable,
      WorkPreview
    }, 
    data() {
      return {
        loading: true,
        timer:null,
        search_job_name: '',
        url: {
          list: '/business/noPermission/getYsLearningList',
          getJobNameList: '/business/noPermission/getJobNameList',
          apiUrlPost: '/jsonApi/post?needDecryption=' + this.userInfo().username,
          calcYsLearningDuration: '/business/noPermission/calcYsLearningDuration',
          learningCompleteLabel: '/business/noPermission/learningCompleteLabel'
        }, 
        userInfos:{}, 
        jonNameList:[],
        dataSource: [],
        totalDesserts: 0,
        options: {
          itemsPerPage: 10,
          page: 1
        },
        indexFile: {},
        dialogPreview:false,
        PUBLIC_KEY: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC640axzpwERnNvfBR1aNc3sR4+vBk/pjLK+1ElWn3OdDvhcjwdX7hYGOO5mEkKntVOtlYeEgqNBZwzo2eT5TcXKzPriKbgiqejQcFcM76I4D5HOa1P+fcoBkQKBahOQGM9YaM/3FtdyPALehFs040GSQrx6OZNjVT3czdQwi6DowIDAQAB',
      }
    },
    computed: {
      headers() {
        return [
          { text: '类别', value: 'job_name', sortable: false },
          { text: '名称', value: 'file_name', sortable: false }, // {{e.target_duration ? e.target_duration + '分钟' : ''}}
          { text: '目标时长', value: 'target_duration', sortable: false },
          { text: '阅读时长', value: 'cumulative_duration', sortable: false }, 
        ]
      }
    },
    watch:{
        dialogPreview(newval, oldVal){
            if(newval){
                if(matchFileSuffixType(this.indexFile.file_name) !== 'video'){
                    this.timer = setInterval(() => {
                        this.timeKeeping()
                    }, 30 * 1000)
                }
            } else {
                if(this.timer){
                    clearInterval(this.timer)
                    this.timer = null
                }
            }
        }
    },
    methods: {
        ...mapGetters(['userInfo']),
        timeKeeping(){
            postAction(this.url.calcYsLearningDuration, {
                "ysJobUploadId": this.indexFile.id, //作业上传的ID
                "userId": this.userInfo().username, // 用户
                "type": "0" // 1:初次进入预览页面；0：正常预览
            }).then(res=>{})
        },
        // 查询
        handleSearch(){
            this.loadData()
        },
        loadData() {
            this.loading = true
            let params = {
                userId: this.userInfo().username
            }
            if(this.search_job_name){
                params.jobName = this.search_job_name
            }else{
                delete params.jobName
            }
            postAction(this.url.list, params).then(res => {
            if (res.success) {
                let dataList = res.result //.records
                if(dataList && dataList.length>0){
                    dataList.forEach(e => {
                        const duration = e.cumulative_duration || 0;
                        var hours = parseInt((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        var minutes = parseInt((duration % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = (duration % (1000 * 60)) / 1000;
                        if(e.cumulative_duration){
                            if(hours == 0){
                                e.cumulative_duration = `${minutes}分钟`
                            }else{
                                e.cumulative_duration = `${hours}小时${minutes}分钟`
                            }
                        }else{
                            e.cumulative_duration = '0时0分钟'
                        }
                    })
                } 
                this.dataSource = dataList 
            } else {
                this.$message.error(res.message)
            }
            this.loading = false
            })
        },
        // 分页
        pagination() {
            this.loadData()
        },
        // 获取类别列表
        getJobNameListForOnlinelearn(){ 
            // 获取缓存 
            this.userInfos = this.userInfo()
            postAction(this.url.getJobNameList,{
                "userId":  this.userInfos.username
            }).then(res => {
                if(res.success){
                    const data = res.result
                    if (data.length) {
                        data.forEach((item, i) => {
                            this.jonNameList.push({
                                text: item.job_name,
                                value: item.job_name // item.ys_job_id
                            })
                        })
                    }
                }else{
                    this.jonNameList = []
                } 
            })
        },
        lookFile(item, type, page){
            if (type == 'lookFile') {
				let param = encodeURIComponent(JSON.stringify({
					"function": {
						"currentUser()": "currentUser()",
						"createTime()": "createTime()",
						"orgCode()": "orgCode()",
						"getJobNumber()": "getJobNumber()"
					},
					"Ys_job_person_look": { //作业
						"create_by@": "function/currentUser",
						"create_time@": "function/createTime",
						"sys_org_code@": "function/orgCode",
						"ys_job_person_id": item.ys_job_person_id || '', //作业人员ID
						"ys_job_upload_id": item.id //作业文件ID
					}
				}))
				const jsEncrypt = new JSEncrypt();
				jsEncrypt.setPublicKey(this.PUBLIC_KEY);
                let newParam = jsEncrypt.encryptLong(param)
                httpAction2(this.url.apiUrlPost, newParam, 'post', 'application/json').then(res => {})
                this.indexFile = item
                if (item.file_name.length > 0){
                    this.dialogPreview = true 
                    this.onshowDialog() // 打开开启预览时长设置
                }
			}
        },
        // 开启预览时长
        onshowDialog(){ 
            postAction(this.url.calcYsLearningDuration, {
                "ysJobUploadId": this.indexFile.id, //作业上传的ID
                "userId": this.userInfo().username, // 用户
                "type": "1" // 1:初次进入预览页面；0：正常预览
            }) 
        },
        // 关闭预览
        closeDialogPreview(){
            if(!!this.$refs.myWorkPreview && matchFileSuffixType(this.indexFile.file_name) === 'video'){
                this.$refs.myWorkPreview.pauseVideo()
            }
            this.dialogPreview = false
            if(this.timer){
                clearInterval(this.timer) 
                this.timer = null
                this.loadData()
            } 
        },
        voideoPlay(){
            // 视频开启播放
            if(this.timer === null){
                this.timer = setInterval(() => {
                    this.timeKeeping()
                }, 30 * 1000)
            }
        },
        voideoPause(){
            // 视频暂停播放
            if(!!this.timer){
                clearInterval(this.timer)
                this.timer = null
            }
        },
        voideoEnded(){
            // 视频播放结束
            if(!!this.timer){
                clearInterval(this.timer)
                this.timer = null
            }
            postAction(this.url.learningCompleteLabel, {
                "ysJobUploadId": this.indexFile.id, //作业上传的ID
                "userId": this.userInfo().username, // 用户
                "type": "0" // 1:初次进入预览页面；0：正常预览
            }).then(res=>{})
        }
    },
    created() { 
        this.getJobNameListForOnlinelearn()
        this.loadData()
    }
  }
  </script>
  <style>
  .lmind-table .v-data-table >
  .lmind-table .v-data-table__wrapper >
  table > tbody > tr > th,
  .lmind-table .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .lmind-table .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
    FONT-WEIGHT: 600;
    font-size: 1rem;
  }
  .lmind-table .v-data-table-header{
    background:#ddd;
  }
  .lmind-table .v-data-table td{
    font-weight: 500;
  }
  /* 调整v-select的位置 */
  .custom-select   {
    position: relative;
    top: 0;
    left: 0;
  } 
  /* 如果需要调整下拉菜单的位置 */
  .custom-select .v-menu__content {
    margin-top: 5px; /* 根据需要调整 */
  }
  </style>