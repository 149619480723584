var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"lmind-table mt-0",staticStyle:{"padding":"15px","position":"relative"}},[_c('v-row',{staticStyle:{"padding":"15px"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"custom-select"},[_c('v-autocomplete',{attrs:{"items":_vm.jonNameList,"item-text":"text","item-value":"value","dense":"","clearable":"","label":"选择类别","placeholder":"请选择类别","outlined":"","hide-details":"auto"},model:{value:(_vm.search_job_name),callback:function ($$v) {_vm.search_job_name=$$v},expression:"search_job_name"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSearch}},[_vm._v("查询")])],1)])],1),_c('v-data-table',{staticClass:"elevation-1 ",attrs:{"headers":_vm.headers,"items":_vm.dataSource,"item-key":"id","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.job_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.job_name))])]}},{key:"item.file_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer","color":"#437EE2"},on:{"click":function($event){return _vm.lookFile(item, 'lookFile', 'onlineLearn')}}},[_vm._v(_vm._s(item.file_name))])]}},{key:"item.target_duration",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.target_duration ? item.target_duration + '分钟' : ''))])]}},{key:"item.cumulative_duration",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.cumulative_duration))])]}}],null,true)}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogPreview),callback:function ($$v) {_vm.dialogPreview=$$v},expression:"dialogPreview"}},[_c('v-card',{staticClass:"workpre"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.indexFile.file_name))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialogPreview}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('WorkPreview',{ref:"myWorkPreview",attrs:{"filename":_vm.indexFile.file_name},on:{"voideoPlay":_vm.voideoPlay,"voideoPause":_vm.voideoPause,"voideoEnded":_vm.voideoEnded}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }